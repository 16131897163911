.table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
}

.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 1rem;
	background-color: transparent;
}

table {
	border-collapse: collapse;
}

.table tr td {
	vertical-align: middle;
}

.table thead tr th {
		font-size: 1.063rem!important;
		font-weight: 300;
		border-bottom: 2px solid rgba(0,0,0,.06);
		border-top: none;
}

.react-datepicker-popper {
		z-index: 9999 !important;
}
