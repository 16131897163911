.card {
	border: none;
	transition: .3s ease;
	position: relative;
	padding: 30px;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
	border-radius: .25rem;
	// overflow: hidden;
	margin-bottom: 25px;

	&:hover {
		&:before {
			opacity: .8;
		}
	}

	&-body {
		position: relative;
		padding: 0!important;
		// z-index: 1;
	}

	.badge {
		position: absolute;
		top: -12px;
		right: -9px;
	}

	.btn {
		@media (max-width: 1199px) {
			font-size: .775rem;
		}
	}
}

.card-home {
		&:before {
			content: '';
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			z-index: 0;
			background-color: #fff;
			transition: .3s ease;
			opacity: .5;
		}
}
