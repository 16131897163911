$green: #3cab26;

$black: #333;
$white: #fff;
$blue: #013a82;
$red: #B22222;

$primary_dark_blue: #001e48;
$primary_red: #B22222;
$primary_blue: #337ab7;

$primary_green: #25a549;
