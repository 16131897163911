@import "colors";

.App {
	text-align: center;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

.form-control-plaintext {
	outline: none;
	cursor: default;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
	background-image: none;
}

.heading {
	position: relative;
	margin-bottom: 1rem;

	.title {
		position: relative;
		z-index: 1;
		margin-top: 0;
		display: inline-block;
		background-color: #fff;
		padding-right: 1rem;
	}
}

a,
button {
	svg + span,
	i + span,
	span + i,
	span + svg {
		margin-left: 4px;
	}
}

.InputFromTo
	.DayPicker-Day--selected:not(.DayPicker-Day--start):not(
		.DayPicker-Day--end
	):not(.DayPicker-Day--outside) {
	background-color: #f0f8ff !important;
	color: #4a90e2;
}
.InputFromTo .DayPicker-Day {
	border-radius: 0 !important;
}
.InputFromTo .DayPicker-Day--start {
	border-top-left-radius: 50% !important;
	border-bottom-left-radius: 50% !important;
}
.InputFromTo .DayPicker-Day--end {
	border-top-right-radius: 50% !important;
	border-bottom-right-radius: 50% !important;
}
.InputFromTo .DayPickerInput-Overlay {
	width: 550px;
}
.InputFromTo-to .DayPickerInput-Overlay {
	margin-left: -198px;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.custom-file-label::after {
	content: "Выбрать";
}

.long-container {
	width: "100%";
	padding: 0 2%;
}

.homepage .card-title {
	color: white !important;
	position: relative;
}

.card-body {
	// overflow: visible!important;
	overflow: auto;
	position: unset !important;
}

tbody td {
	position: relative;
	z-index: 1;
}

.DayPickerInput-OverlayWrapper {
	z-index: 10;
}

.payment_form {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.group_size_selector {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	border: 1px solid lightgrey;
	padding: 15px;
	cursor: pointer;

	.sizes_label {
		font-size: 20px;
		margin-top: 10px;
	}
	img {
		background-color: white !important;
		padding: 5px 20px;
		border-radius: 5px;
		width: 100%;
	}
}
.selected {
	border: 1px solid rgba(60, 171, 38, 0.7) !important;
	box-shadow: rgba(60, 171, 38, 0.55) 0px 20px 70px -20px,
		rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.sizes {
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.size_item {
	border: 1px solid lightgrey;
	border-radius: 5px;
	padding: 20px;
	margin: 0 7px;
	position: relative;
	cursor: pointer;
	min-width: 100px;

	.size_item_size_label {
		font-size: 18px;
		font-weight: 500;
		margin-bottom: 5px;
	}

	.size_item_price_label {
	}

	.size_item_not_available {
		background: rgba(241, 241, 241, 0.8);
		box-shadow: 0 9px 17px 1px rgba(20, 21, 19, 0.3);
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		padding: 5px;
		cursor: not-allowed;

		.size_item_not_available_text {
			font-weight: 600;
			font-size: 20px;
		}
	}
}

.payment_frequencies {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	.payment_frequencies_item {
		border: 1px solid lightgrey;
		border-radius: 5px;
		padding: 20px;
		position: relative;
		cursor: pointer;

		.payment_frequencies_item_label {
			font-size: 18px;
			font-weight: 500;
			margin-bottom: 5px;
		}
	}

	.payment_frequencies_item.selected {
		background: #ebffe7;
	}

	.not_selected_payment_frequency {
	}
}

.payment_frequencies.selected {
	background: #ebffe7;
}

.account-type-item {
	text-align: center;
	font-size: 20px;
	margin: 10px;
	padding: 30px;
	border: 1px solid lightgrey;
	border-radius: 5px;
	cursor: pointer;
}

.indicatorContainer {
	color: green;
}

.StripeElement {
	height: 50px;
	padding: 15px 12px;
	font-size: 18px;
	width: 100%;
	color: #32325d;
	background-color: white;
	border: 1px solid #038004;
	border-radius: 4px;

	box-shadow: 0 1px 3px 0 #e6ebf1;
	-webkit-transition: box-shadow 150ms ease;
	transition: box-shadow 150ms ease;
}

.StripeElement--focus {
	box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
	border-color: #fa755a;
}

.StripeElement--webkit-autofill {
	background-color: #fefde5 !important;
}

.InputElement {
	font-size: 18px;
}

.border-card {
	background: #fff;
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 14px;
	padding: 12px 16px;
	cursor: pointer;
	border-radius: 4px;
	border: 1px solid $primary_green;
	box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.1);
	transition: all 0.25s ease;
}

.border-card_box_info {
	display: flex;
	width: 100%;
	align-items: center;
	flex-direction: row;
}

.back-button {
	font-size: 18px;
	color: $blue;
	font-weight: normal;
	cursor: pointer;
	transition: 300ms;
}

.back-button:hover {
	transform: translateX(10px);
}

.active_item {
	border: 2px solid $primary_green !important;
	box-shadow: 0 5px 10px 0 rgba(37, 166, 73, 70%);
}

.border-card:hover {
	-webkit-transform: translateY(-1px);
	transform: translateY(-1px);
	box-shadow: 0 5px 10px 0 rgba(37, 166, 73, 70%);
}

.border-card.over {
	background: rgba(70, 222, 151, 0.15);
	padding-top: 24px;
	padding-bottom: 24px;
	border: 2px solid $primary_green;
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0), 0 5px 10px 0 rgba(0, 0, 0, 0);
}

.border-card.over .card-type-icon {
	color: $primary_green !important;
}

.border-card.over p {
	color: $primary_green !important;
}

.table-scroll {
	height: 550px !important;
	overflow: scroll;
}

.content-wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
	white-space: nowrap;
	transition: all 0.25s ease;
	margin-left: 10px;
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex-vertical-center {
	display: flex;
	justify-content: center;
	flex-direction: column;
}
.flex-vertical {
	display: flex;
	flex-direction: column;
}

label {
	margin-bottom: 0;
}

.ant-steps-item-icon {
	// border-radius: 7px;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
	background: $primary_blue;
	border-color: $primary_blue;
}

.ant-steps-item-finish .ant-steps-item-icon {
	border-color: $primary_blue;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
	color: $primary_blue;
}

.ant-steps-item-finish
	> .ant-steps-item-container
	> .ant-steps-item-content
	> .ant-steps-item-title:after {
	border-color: $primary_blue;
	background: $primary_blue;
}
.ant-steps-item-icon .ant-steps-icon {
	top: -2px;
}

.navbar {
	background: $primary_dark_blue;
	color: $white;
}

.navbar-nav .ant-steps-item-content .ant-steps-item-title {
	color: $white !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
	background: $primary_green !important;
	border-color: $primary_green !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
	border-color: $primary_green !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
	color: $primary_green !important;
}

.flex-wrap {
	flex-wrap: wrap;
}

.ant-tabs-nav {
	margin: 7px -24px !important;
}

.ant-tabs-nav-operations {
	display: none !important;
}

.navbar-expand-lg {
	z-index: 100;
}

.right {
	float: right;
}

.label-group p.caption {
	font-weight: 400;
	color: #aeaeae;
}

.pdf-viewer {
	width: 100%;
	height: 150px;
	position: relative;
}

.pdf-viewer.full-screen {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
}

.pdf-viewer iframe {
	width: 100%;
	height: 100%;
	border: none;

	zoom: 0.75;
	-moz-transform: scale(0.75);
	-moz-transform-origin: 0 0;
}

.pdf-viewer-close {
	width: 5%;
	background-color: black;
	color: white;
	font-size: 24px;
}

.badge.badge-2 {
	top: 10px;
}
