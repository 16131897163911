.period-item {
		border: solid;
	}
	
.my-input {
		height: 1.4em;
		width: 1.4em;
}
	
.label {
		align-self: flex-end;
}
