@import '../../../../../assets/styles/colors.scss';

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.payment-block {
  display: grid;
  gap: 10px;
  margin-bottom: 25px;

  &__row {
    display: flex;
  }

  &__cards {
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #28a745;
    color: #28a745;
    padding: 20px 80px;

    &__row {
      padding: 20px 0px;
      width: 250px
    }

    &:hover {
      background-color: #28a745;
      color: white;
      cursor: pointer;
    }
  }

  &__image {
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    color: #28a745;
  }
}

.active {
  background-color: $primary_green;
  color: white;
}

@media screen and (max-width: 1080px) {
  .payment-block__row {
      display: grid;
  }
}
