@import '../../../../../assets/styles/colors.scss';

.react-datepicker {
	font-size: 1.2em;
}

.react-datepicker__header {
	padding-top: 0.8em;
}

.react-datepicker__month {
	margin: 0.4em 1em;
}

.react-datepicker__day-name,
.react-datepicker__day {
	width: 1.9em;
	line-height: 1.9em;
	margin: 0.166em;
}

.react-datepicker__current-month {
	font-size: 1em;
}

.react-datepicker__navigation {
	top: 1em;
	line-height: 1.7em;
	border: 0.45em solid transparent;
}

.react-datepicker__navigation--previous {
	border-right-color: #ccc;
	left: 1em;
}

.react-datepicker__navigation--next {
	border-left-color: #ccc;
	right: 1em;
}

.react-datepicker__input-container input {
	font-size: 1.4em;
}

.error .react-datepicker__input-container input {
	border-color: $primary_red;
}

.choice-of-arrival-date {
	width: 100%;
}
