@import '../../../assets/styles/colors.scss';

.sidebar_footer {
	position: fixed;
	bottom: 0px;
	padding: 20px;
}

.sidebar_footer__buttons_wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.sidebar_footer__total_price {
	font-weight: bold;
	font-size: 24px;
}

.ant-tabs-card .ant-tabs-tab-active {
	background: $primary_blue!important;
	border-color: #fff;
}

.ant-tabs-tab-active > .ant-tabs-tab-btn {
	color: $white!important;
}

.label-group {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

#boxes-list {
	padding-top: 65px!important;
}
