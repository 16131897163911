header.header {
	background-color: #001e48;
	border-bottom: 1px solid #001e48;
	color: #fff;
}

.navbar {
	// border-bottom: 1px solid #3fab2e;
	padding-left: 0;
	padding-right: 0;
}

.header a {
	color: inherit;
}

.header .logo {
	width: 200px;
}


@media screen and (max-width: 768px) {
	.nav-link {
		// padding-left: 10px !important;
		// padding-right: 10px !important;
		display: flex;
		justify-content: center;
		align-items: center;
		align-content: center;

		& > i {
			margin-right: 5px;
		}
	}

	.navbar-brand {
		padding: 10px;
	}

}
