div.legenda {
	display: inline-block;
	margin-left: 5px;
	margin-right: 20px;
}

.legenda-text {
	margin-left: 5px;
}

.scheme {
	overflow: scroll;
	margin-bottom: 1rem;
	margin-top: 1rem;
}

.stock-svg {
	max-height: 600px;
}
